
























import {Prop} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';

import storeBoisQuality from '@/stores/modules/boisQuality';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
	}
})
export default class CrudBoisQualityList extends Vue {
	
	@Prop({type: Boolean, default: true  }) private paginate!: boolean;
	@Prop({type: Boolean, default: false }) private dense!   : boolean;
	
	private storeBoisQuality = storeBoisQuality;
}
