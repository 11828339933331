import {InOut} from 'vue-inout-prop-decorator';
import {Watch} from "vue-property-decorator";
import {Vue} from '@/shared/component';
import {Store} from 'vuex';

export abstract class AbstractShowVue<T extends any> extends Vue {
	@InOut({ type: Object, isVModel: true }) protected value!: T;

	private notFound: boolean = false;
	protected currentStore: Store<any>;

	public constructor(
		currentStore: Store<any>,
	) {
		super();
		this.currentStore = currentStore;
	}

	public get item(): T {
		return this.currentStore.state['current'];
	}

	public set item(value: T) {
		this.currentStore.commit('setCurrent', value);
	}

	public async mounted(): Promise<void> {
		this.item = null;
		await this.refresh(this.value);
	}

	@Watch('value')
	public async refresh(value: any, noEmpty: boolean = false): Promise<void> {
		if (value && (this.value !== value || !this.item || value.id !== this.item.id)) {
			try {
				if (!noEmpty) {
					this.item = null;
				}
				await this.currentStore.dispatch('get', this.value.id);
				this.$emit('load');
			} catch (e) {
				if (e.status === 404) {
					this.notFound = true;
				}
			}
		}
		this.$emit('refresh', this.item);
	}
}

