

































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {BoisType} from '@/models';

import CrudBoisTypeFilter from '@/components/Crud/BoisType/Filter.vue';
import CrudBoisTypeEditDialog from '@/components/Crud/BoisType/EditDialog.vue';
import CrudBoisTypeList from '@/components/Crud/BoisType/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeBoisType from '@/stores/modules/boisType';

@Component({
	components: {
		CrudBoisTypeFilter,
		CrudBoisTypeEditDialog,
		CrudBoisTypeList,
		CrudDeleteDialog,
	}
})
export default class BoisTypeView extends Vue {
	
	@Stored(() => storeBoisType)
	private boisTypes!: ResultType<BoisType>;
	
	private editBoisType: BoisType = null;
	private deletedBoisType: BoisType = null;
	private storeBoisType = storeBoisType;
	
	@Emit()
	private onCreate(): void {
		this.editBoisType = new BoisType();
	}

	private refresh(): void {
		storeBoisType.dispatch('getC', { filters: true });
	}
}
