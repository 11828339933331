







































































































































































































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Chantier} from '@/models';

import storeChantier from '@/stores/modules/chantier';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import CrudContactSearch from '@/components/Crud/Contact/Search.vue';
import CrudAbatteurSearch from '@/components/Crud/Abatteur/Search.vue';
import CrudDebardeurSearch from '@/components/Crud/Debardeur/Search.vue';

@Component({
	components: {
		InputDatePicker,
		CrudContactSearch,
		CrudAbatteurSearch,
		CrudDebardeurSearch,
	}
})
export default class CrudChantierEditPlanning extends AbstractEditVue<Chantier> {
	public constructor() {
		super(
			storeChantier,
			'Chantier modifié',
			'Chantier créé',
		);
	}
}
