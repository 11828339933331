import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize} from '@/models';
import {BoisQualityService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class BoisQualityFilter {
	
	public name: string = '';
	
	public toString(): string {
		return JSON.stringify(this);
	}
}

class BoisQualityState extends PaginatorFilterState<BoisQualityFilter> {
	public boisQuality: BoisQuality = null;
	public boisQualities: ResultType<BoisQuality> = null;
	public boisQualitiesAll: ResultType<BoisQuality> = null;
	public constructor() {
		super(new BoisQualityFilter(), 'boisQualities', 'boisQuality');
	}
}

class BoisQualityStore {
	
	@XHTTPService(() => BoisQuality)
	private boisQualityService: BoisQualityService;
	
	public state: BoisQualityState = new BoisQualityState();
	
	public mutations = {
		...PaginatorMutation(() => storeBoisQuality, 'boisQualities', 'boisQuality'),
	};
	
	public actions = {
		...StandardAction<BoisQuality, BoisQualityState>(() => BoisQuality, 'boisQuality'),
		...PaginatorActionWithFilters<BoisQuality, BoisQualityState>(() => BoisQuality, 'boisQualities', 'boisQuality'),
	};
	
}
const instance = new BoisQualityStore();
const storeBoisQuality = new Vuex.Store(instance);
store.registerModule('boisQuality', storeBoisQuality);
export default storeBoisQuality;
