


































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

	import storeChantier from '@/stores/modules/chantier';

	import InputFraisChantiersList from '@/components/Input/FraisChantiersList.vue';
    import {Prestation} from "@/models";

	@Component({
		components: {
            InputFraisChantiersList,
		}
	})
	export default class CrudChantierEditFraisChantiers extends AbstractEditVue<Prestation> {
		public constructor() {
			super(
				storeChantier,
				'Frais modifié',
				'Frais créé',
			);
		}

	}
