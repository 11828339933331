










































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Chantier} from "@/models";
	import {ArrayHelper} from "@/shared/utils";

	import storeCubageChantier, {CubageChantierFilter} from '@/stores/modules/cubageChantier';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';
	
	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
		}
	})
	export default class CrudCubageChantierList extends Vue {
		
		@Prop({type: Boolean, default: true  }) public paginate!  : boolean;
        @Prop({type: Boolean, default: false }) public dense!     : boolean;
        @Prop({type: Boolean, default: false }) public hideAction!: boolean;
		@Prop({ type: [ Chantier, Boolean ], default: false }) public chantier: Chantier;

		@Stored(() => storeCubageChantier) private filters!: CubageChantierFilter;
		
		private storeCubageChantier = storeCubageChantier;

		public get show(): boolean {
			return this.chantier !== null;
		}
		
		public mounted(): void {
			this.watcher();
		}
		
		@Watch('livraison')
		@Watch('chantier')
		public watcher(): void {
			if (this.chantier) {
				if (!ArrayHelper.equal(this.filters.chantier, [ this.chantier ])) {
					this.filters.chantier = [ this.chantier ];
				}
			} else {
				this.filters.chantier = [];
			}
			if (this.chantier) {
				if (!ArrayHelper.equal(this.filters.chantier, [ this.chantier ])) {
					this.filters.chantier = [ this.chantier ];
				}
			} else {
				this.filters.chantier = [];
			}
		}
	}
