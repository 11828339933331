








































































































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {ResultType} from '@/shared/xhttp';
import {Prestation} from '@/models';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import axios from "axios";

import { icon, latLng } from "leaflet";
import { LMap, LMarker, LTileLayer, LControl, LTooltip, LPopup, LCircleMarker, LIcon  } from "vue2-leaflet";
import storeToken from "@/stores/modules/token";
import {Token, User} from "@/models";

import storePrestation, {PrestationFilter} from '@/stores/modules/prestation';

@Component({
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LIcon,
		LControl,
		LTooltip,
		LPopup,
		LCircleMarker,
	}
})
export default class PrestationMapView extends Vue {

	@Stored(() => storeToken) private token: Token;

	lat = 46.08014897989135;
	lng = 4.386728978266652;
	center;
	zoom = 13;
	url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
	urlsat = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';
	attribution =
		'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
	showMap = false;
	private showMapSatellite : boolean  = false;
	private opacitySat : number = 0;
	markers;
	subdomains = ["mt0", "mt1", "mt2", "mt3"];

	private filtreContrat  = '';
	private filtreCommune = '';
	private filtreClient  = '';
	private AvecGrumes : boolean  = true;
	private AvecBillons : boolean  = true;
	private ToutFini : boolean  = false;

	private filtreContratText  = '';
	private filtreCommuneText = '';
	private filtreClientText  = '';
	private filtreAvecGrumesText  = '';
	private filtreAvecBillonsText  = '';
	private filtreToutFiniText  = '';
	//private icon = new icon("static/images/baseball-marker.png");

	async mounted() {
		this.LoadData();
		this.center = latLng(this.lat, this.lng);
	}

	@Watch('showMapSatellite')
	onOpacitySatChanged(value: boolean, oldValue: boolean) {
		if (this.showMapSatellite == true)
			this.opacitySat = 1;
		else
			this.opacitySat = 0;
	}


	@Watch('filtreContrat')
	@Watch('filtreCommune')
	@Watch('filtreClient')
	@Watch('AvecGrumes')
	@Watch('AvecBillons')
	@Watch('ToutFini')
	onPropertyChanged(value: string, oldValue: string) {
		this.LoadData();
	}
	private async CalculFiltre()
	{
		if ((this.filtreContrat == '') || (this.filtreContrat == 'null') || (this.filtreContrat == null))
			this.filtreContratText = 'NULL';
		else
			this.filtreContratText = this.filtreContrat;

		if ((this.filtreCommune == '') || (this.filtreCommune == 'null') || (this.filtreCommune == null))
			this.filtreCommuneText = 'NULL';
		else
			this.filtreCommuneText = this.filtreCommune;

		if ((this.filtreClient == '') || (this.filtreClient == 'null') || (this.filtreClient == null))
			this.filtreClientText = 'NULL';
		else
			this.filtreClientText = this.filtreClient;

		if (this.AvecGrumes == true)
			this.filtreAvecGrumesText = '1';
		else
			this.filtreAvecGrumesText = '0';

		if (this.AvecBillons == true)
			this.filtreAvecBillonsText = '1';
		else
			this.filtreAvecBillonsText = '0';

		if (this.ToutFini == true)
			this.filtreToutFiniText = '1';
		else
			this.filtreToutFiniText = '0';

	}

	private async LoadData()
	{
		this.CalculFiltre();

		await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/chantiers/maps/" + this.filtreContratText + "/" + this.filtreCommuneText + "/" + this.filtreClientText + "/" + this.filtreAvecGrumesText + "/" + this.filtreAvecBillonsText + "/" + this.filtreToutFiniText + "?token=" + this.token.id, {}).then(async response => {
			this.markers = await response.data;
		});

		this.$forceUpdate();
	}


	private ClearContrat() : void {
		this.LoadData();
	}

	private ClearCommune() : void {
		this.LoadData();
	}

	private ClearClient() : void {
		this.LoadData();
	}

}
