
















    import {Component, Vue} from '@/shared/component';
	import {Prop} from "vue-property-decorator";
    import {Stored} from 'vue-stored-prop-decorator';
	import {ObjectString} from 'gollumts-objecttype';
	import {Upload} from '@/models';

	import storeUpload from "@/stores/modules/upload";

    @Component()
    export default class UploadProgress extends Vue {

        @Stored(() => storeUpload) private progresses: ObjectString<number>;
        @Prop({ type: Upload, required: false, default: null }) private value: Upload;

        private get show(): boolean {
            return this.value && typeof this.progresses[this.value.uuid] !== 'undefined';
        }

        private get progressValue(): number {
            return this.progresses[this.value.uuid] ? this.progresses[this.value.uuid] : 0;
        }
    }
