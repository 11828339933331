







































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {PrestationType} from '@/models';

import CrudPrestationTypeEdit from '@/components/Crud/PrestationType/Edit.vue';

@Component({
	components: {
		CrudPrestationTypeEdit,
	}
})
export default class CrudPrestationTypeEditDialog extends Vue {
	@InOut({ type: PrestationType  , isVModel: true }) private value!: PrestationType;

	@Emit()
	private onSubmit(prestationType: PrestationType) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
