












































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {CubageChantier, User} from '@/models';

	import storeCubageChantier from '@/stores/modules/cubageChantier';
    import validator from "@/shared/validator";

	import {Stored} from 'vue-stored-prop-decorator/index';
	import storeUser from '@/stores/modules/user';

	import CrudDocumentCubageUploader from '@/components/Crud/DocumentCubage/Uploader.vue';
	import CrudDocumentCubageShow from '@/components/Crud/DocumentCubage/Show.vue';
	
	@Component({
		components: {
			CrudDocumentCubageUploader,
			CrudDocumentCubageShow,
		}
	})
	export default class CrudCubageChantierEditDocument extends AbstractEditVue<CubageChantier> {

        validator = validator
	    
		public constructor() {
			super(
				storeCubageChantier,
				'Cubage modifié',
				'Cubage créé',
			);
		}
	}
