





























































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {ResultType} from '@/shared/xhttp';
import {Role} from '@/models';
import {DateFilter} from '@/shared/filter';

import storeRole from '@/stores/modules/role';
import storeUser, {UserFilter} from '@/stores/modules/user';

import InputDateFilter from '@/components/Input/DateFilter.vue';
import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';

@Component({
	components: {
		InputDateFilter,
		TrueFalseMultiSelect,
	}
})
export default class CrudUserFilter extends Vue {
	
	@Prop({ type: Boolean, default: false}) reduce;
	
	@Stored(() => storeRole) private roles!: ResultType<Role>;
	@Stored(() => storeUser) private page!: number;
	@Stored(() => storeUser) private filters!: UserFilter;
	
	public async mounted(): Promise<void> {
		await storeRole.dispatch('getCNoRefresh');
		if (this.reduce) {
			this.filters.phone = '';
			this.filters.roles = [];
			this.filters.createdAt = new DateFilter();
		}
	}
	
	@WatchObjectProperties('filters', UserFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeUser.dispatch('getC', { filters: true });
	}
	
}
