
































































































import {Stored} from 'vue-stored-prop-decorator'
import {Component} from '@/shared/component';
import {ResultType} from '@/shared/xhttp';
import {Role, User} from '@/models';

import storeUser from '@/stores/modules/user';
import storeRole from '@/stores/modules/role';

import InputPhone from '@/components/Input/Phone.vue';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

@Component({
	components: {
		InputPhone
	}
})
export default class CrudUserEdit extends AbstractEditVue<User> {
	
	private showPassword: boolean = false;
		
	@Stored(() => storeRole)
	private roles!: ResultType<Role>;
	
	public constructor() {
		super(
			storeUser,
			'Utilisateur modifié',
			'Utilisateur créé',
		);
	}
	
	public get itemsRoles(): Role[] {
		return this.roles.data.filter(role => role.name !== 'ROLE_USER');
	}
	
	public async mounted(): Promise<void> {
		await storeRole.dispatch('getCNoRefresh');
		await super.mounted();
	}
}
