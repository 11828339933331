



















	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {Chantier, Prestation} from '@/models';

	import storeChantier from '@/stores/modules/chantier';

  import {Watch} from 'vue-property-decorator';
  import CrudDocumentChantierUploaderPhoto from '@/components/Crud/DocumentChantier/UploaderPhoto.vue';
  import CrudDocumentChantierShowPhoto from '@/components/Crud/DocumentChantier/ShowPhoto.vue';

	@Component({
		components: {
      CrudDocumentChantierUploaderPhoto,
      CrudDocumentChantierShowPhoto,
		}
	})
	export default class CrudChantierPhotosEdit extends AbstractEditVue<Chantier> {

		public constructor() {
			super(
				storeChantier,
				'Chantier modifié',
				'Chantier créé',
			);
		}

		@Emit()
		private onNext() {
		}

		protected async onSubmit(): Promise<void> {
			await super.onSubmit();
		}

		public async mounted(): Promise<void> {
      await super.mounted();
     
		}

	}
