import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Contact, Tiers} from '@/models';
import {ContactService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class ContactFilter {
	
	firstName: string = '';
	lastName: string = '';
	tiers: Tiers[] = [];
	
	public clear(): void {
		this.firstName = '';
		this.lastName = '';
		this.tiers = [];
	}

	public toString(): string {
		return JSON.stringify({
			firstName: this.firstName,
			lastName : this.lastName,
			tiers    : this.tiers.map(t => t.id),
		});
	}
}

class ContactState extends PaginatorFilterState<ContactFilter> {
	public contact: Contact = null;
	public contacts: ResultType<Contact> = null;
	public constructor() {
		super(new ContactFilter(), 'contacts', 'contact', 'id', AscDesc.DESC, 50);
	}
}

class ContactStore {
	
	@XHTTPService(() => Contact)
	private contactService: ContactService;
	
	public state: ContactState = new ContactState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeContact, 'contacts', 'contact'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<Contact, ContactState>(() => Contact, 'contacts', 'contact'),
		...StandardAction<Contact, ContactState>(() => Contact, 'contact'),
	};
	
}
const instance = new ContactStore();
const storeContact = new Vuex.Store(instance);
store.registerModule('contact', storeContact);
export default storeContact;
