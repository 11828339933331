import email from './email';
import password from './password';
import range from './range';
import required from './required';

const validator = {
	email,
	password,
	range,
	required,
};
export default validator;
