













































































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {CubageLivraison, Livraison} from '@/models';

import CrudCubageLivraisonFilter from '@/components/Crud/CubageLivraison/Filter.vue';
import CrudCubageLivraisonEditDialog from '@/components/Crud/CubageLivraison/EditDialog.vue';
import CrudCubageLivraisonEditFactureDialog from '@/components/Crud/CubageLivraison/EditFactureDialog.vue';
import CrudCubageLivraisonList from '@/components/Crud/CubageLivraison/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
import CrudLivraisonEditDialog from '@/components/Crud/Livraison/EditDialog.vue';

import storeCubageLivraison from '@/stores/modules/cubageLivraison';
import storeLivraison from '@/stores/modules/livraison';

@Component({
	components: {
		CrudCubageLivraisonFilter,
		CrudCubageLivraisonEditDialog,
		CrudCubageLivraisonEditFactureDialog,
		CrudCubageLivraisonList,
		CrudDeleteDialog,
		CrudLivraisonEditDialog,
		storeLivraison,
	}
})
export default class CubageLivraisonView extends Vue {

	@Stored(() => storeCubageLivraison)
	private livraisons!: ResultType<CubageLivraison>;

	private editLivraison: Livraison = null;
	private editCubageLivraison: CubageLivraison = null;
	private editNumFacture: CubageLivraison = null;
	
	private deletedLivraison: CubageLivraison = null;
	private storeCubageLivraison = storeCubageLivraison;

	private refresh(): void {
		storeCubageLivraison.dispatch('getC', { filters: true });
	}

	private async LanceEditLivraison(item): Promise<void> 
	{
		var oNew = new Livraison();
		oNew.id = item.livraison.id;
		this.editLivraison = oNew; // await storeLivraison.dispatch('get', item.livraison.id);
	}

	private LanceEditNumFacture()
	{
		this.editNumFacture = new CubageLivraison();
	}

	private SelectAll()
	{
		
		this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
			element.selected = true;
		});
	}

	private SelectNone()
	{
		
		this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
			element.selected = false;
		});
	}
}
