import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentCubage} from '@/models';
import {DocumentCubageService} from '@/xhttp';
import store from '@/stores';
//import cubage from "@/router/cubage";
import storeCubage from "@/stores/modules/cubageChantier";

Vue.use(Vuex);

class DocumentCubageState {
}

class DocumentCubageStore {

	@XHTTPService(() => DocumentCubage)
	private documentService: DocumentCubageService;

	public state: DocumentCubageState = new DocumentCubageState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentCubageState, any>, document: DocumentCubage): Promise<DocumentCubage> {
			try {
				document.cubage.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeCubage.dispatch('get', document.cubage.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentCubageStore();
const storeDocumentCubage = new Vuex.Store(instance);
store.registerModule('document', storeDocumentCubage);
export default storeDocumentCubage;
