











































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {DateFilter} from '@/shared/filter';

import storeParcelle, {ParcelleFilter} from '@/stores/modules/parcelle';

import InputDateFilter from '@/components/Input/DateFilter.vue';

@Component({
	components: {
		InputDateFilter,
	}
})
export default class CrudParcelleFilter extends Vue {

	@Prop({ type: Boolean, default: false}) reduce: boolean;

	@Stored(() => storeParcelle) private page!: number;
	@Stored(() => storeParcelle) private filters!: ParcelleFilter;

	@WatchObjectProperties('filters', ParcelleFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeParcelle.dispatch('getC', { filters: true });
	}

	public mounted() {
		this.filters = new ParcelleFilter();
		}

}
