











import {Prop} from "vue-property-decorator";
import {InOut} from "vue-inout-prop-decorator"
import {Component, Vue} from '@/shared/component';
import {EnumLiteral} from "@/shared/utils";;

@Component()
export default class InputEnumSelect extends Vue {

	@InOut({ isVModel: true }) private value!: any;
	@Prop ({ type: Object, required: true }) private enum!: any;
	@Prop ({ type: String, required: true }) private prefixTrans!: string;
	
	private get items(): { name: string, value: any }[] {
		return EnumLiteral.keys(this.enum).map(key => ({
			name: this.$t('enum.'+this.prefixTrans+'.'+key.toLowerCase()).toString(),
			value: this.enum[key],
		}));
	}
}
