









import {Prop} from 'vue-property-decorator';
import {Component, Vue} from '@/shared/component';

import storeMarquage from '@/stores/modules/marquage';
import InputGenericEntitySelect from './GenericEntitySelect.vue';

@Component({
	components: {
		InputGenericEntitySelect
	}
})
export default class InputMarquage extends Vue {

	@Prop ({ type: Function, required: false, default: () => ( () => true ) }) private itemFilter!: () => boolean;
	
	store = storeMarquage;
}
