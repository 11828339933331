

































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from "vue-stored-prop-decorator";
import {Component, Vue} from '@/shared/component';
import {Chantier,CubageChantierRestant} from '@/models';

import storeCubageChantierRestant from "@/stores/modules/cubageChantierRestant";
import storeChantier from "@/stores/modules/chantier";
import storeBoisType from "@/stores/modules/boisType";
import storeBoisQuality from "@/stores/modules/boisQuality";
import storeBoisSize from "@/stores/modules/boisSize";

import Checked from '@/components/Checked.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {NoCache} from "@/shared/decorator";
import {AscDesc, ResultType} from '@/shared/xhttp';

@Component({
	components: {
		TableHead,
		TableCell,
		Checked,
	}
})
export default class CrudCubageChantierRestantForSearchChantier extends Vue {

	@Prop({type: Boolean, default: true  }) private paginate!: boolean;
	@Prop({type: Boolean, default: false }) private dense!   : boolean;

	private storeCubageChantierRestant = storeCubageChantierRestant;
	public get list(): ResultType<CubageChantierRestant> { return this.storeCubageChantierRestant.state[this.listName]; }

	private search = '';
	private headers = [
			{ text: 'Chantier', align: 'start', value: 'chantier' },
			{ text: 'Commune', value: 'chantier.commune' },
			{ text: 'Client', value: 'chantier.client' },
			{ text: 'Type', value: 'boisType' },
			{ text: 'Qualité', value: 'boisQuality' },
			{ text: 'Taille', value: 'boisSize' },
			{ text: 'Volume', value: 'volume' },
			];

	private get show(): boolean {
		return this.isMounted;
	}

	private get  methodName(): string {
		return 'getByBois';
	}
	private get listName(): string {
		return 'cubageChantierRestantsForSearchChantier';
	}

	private async mounted(): Promise<void> {
		await Promise.all([
			(async () => storeBoisType   .state.boisTypesAll || await storeBoisType   .dispatch('getAll'))(),
			(async () => storeBoisQuality.state.boisTypesAll || await storeBoisQuality.dispatch('getAll'))(),
			(async () => storeBoisSize   .state.boisTypesAll || await storeBoisSize   .dispatch('getAll'))(),
		]);
	}

}
