







































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {Chantier} from "@/models";
import {AscDesc} from "@/shared/xhttp";

import storeCubageChantierRestant, {CubageChantierRestantFilter} from "@/stores/modules/cubageChantierRestant";
import storeCubageChantierRestantForSearchChantier, {CubageChantierRestantForSearchChantierFilter} from "@/stores/modules/cubageChantierRestantForSearchChantier";

import InputNumberFilter from '@/components/Input/NumberFilter.vue';
import InputBoisType from '@/components/Input/BoisType.vue';
import InputBoisQuality from '@/components/Input/BoisQuality.vue';
import InputBoisSize from '@/components/Input/BoisSize.vue';

@Component({
	components: {
		InputNumberFilter,
		InputBoisType,
		InputBoisQuality,
		InputBoisSize,
	}
})
export default class CrudCubageChantierRestantForSearchChantierFilter extends Vue {

	@Stored(() => storeCubageChantierRestantForSearchChantier) private filters!: CubageChantierRestantForSearchChantierFilter;

	private get methodName(): string {
		return 'getByBois';
	}

	@WatchObjectProperties('filters', CubageChantierRestantForSearchChantierFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.refresh();
	}

	private refresh(): void {
		storeCubageChantierRestant.dispatch(this.methodName, { filters: true });
	}
}
