












































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {CubageChantier, Chantier} from '@/models';

	import CrudCubageLivraisonList from '@/components/Crud/CubageLivraison/ListForCubageChantier.vue';

	@Component({
		components: {
			CrudCubageLivraisonList,
		}
	})
	export default class CrudCubageLivraisonListDialogForCubageChantier extends Vue {
		@InOut({ type: CubageChantier  , isVModel: true }) private value!: CubageChantier;
		@InOut({ type: Chantier  , isVModel: false }) private chantier!: Chantier;

		@Emit()
		private onSubmit(cubageChantier: CubageChantier) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}

}
