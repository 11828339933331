

















































import {Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Address} from '@/models';

@Component()
export default class Edit extends Vue {
	@Prop ({type: Address, default: null      }) private value!: Address;
	@Prop ({type: String , default: 'Adresse' }) private label: string;
	@Prop ({type: Boolean, default: true      }) private showBody: boolean;
	@Prop ({type: Boolean, default: false     }) private hasToggle: boolean;
	@InOut({type: Boolean, default: false     }) private toggleOpen: boolean;
}
