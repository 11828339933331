import {Native} from "@/shared/utils";

export default ({ min, max }: { min?: number, max?: number }) => {
	return (v: number) => {
		if (!Native.empty(v)) {
			if (min !== null && typeof min !== 'undefined') {
				if (v < min) {
					return 'la valeur ne peut pas être inférieur à '+min;
				}
			}
			if (max !== null && typeof max !== 'undefined') {
				if (v > max) {
					return 'la valeur ne peux pas être supérieur à ' + max;
				}
			}
		}
		return true;
	}
}
