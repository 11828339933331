









































import {Component, Vue} from '@/shared/component';
import {Emit} from "vue-property-decorator";
import {Token} from '@/models';

import CrudTokenList from '@/components/Crud/Token/List.vue';
import CrudTokenEditDialog from '@/components/Crud/Token/EditDialog.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeToken from '@/stores/modules/token';

@Component({
	components: {
		CrudTokenList,
		CrudTokenEditDialog,
		CrudDeleteDialog,
	}
})
export default class TokenView extends Vue {

	private editToken: Token = null;
	private deletedToken: Token = null;
	private storeToken = storeToken;
	
	@Emit()
	private onCreate(): void {
		this.editToken = new Token();
	}

	private refresh(): void {
		storeToken.dispatch('getC', { filters: true });
	}
}
