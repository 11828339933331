
















































import {Component, Vue} from '@/shared/component';
import {Purchase} from '@/models';

import Checked from '@/components/Checked.vue';
import {Prop, Watch} from "vue-property-decorator";
import storePurchase from "@/stores/modules/purchase";

@Component({
	components: {
		Checked,
	}
})
export default class CrudPurchaseShowGeneral extends Vue {
	@Prop({ type: Purchase, required: true }) public purchase: Purchase;
	@Prop({ type: Boolean, default: false }) public forceLoad: boolean;
	
	private item: Purchase = null;

	public async mounted(): Promise<void> {
		await this.purchaseWatcher();
	}
	
	@Watch('purchase')
	private async purchaseWatcher(): Promise<void> {
		this.item = null;
		if (this.purchase) {
			if (this.forceLoad) {
				this.item = await storePurchase.dispatch('get', this.purchase.id);
			} else {
				this.item = this.purchase;
			}
		}
	}
}
