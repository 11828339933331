







































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {User} from '@/models';

import CrudUserEdit from '@/components/Crud/User/Edit.vue';

@Component({
	components: {
		CrudUserEdit,
	}
})
export default class CrudUserEditDialog extends Vue {
	@InOut({ type: User  , isVModel: true }) private value!: User;

	@Emit()
	private onSubmit(user: User) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
