









































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {CubageLivraison, Chantier} from '@/models';
	import storeCubageLivraison from '@/stores/modules/cubageLivraison';
	import axios from "axios";
	import storeToken from "@/stores/modules/token";
	import {Token, User} from "@/models";
	import {Stored} from 'vue-stored-prop-decorator';

	@Component({
		components: {
		}
	})
	export default class CrudCubageLivraisonEditFactureDialog extends Vue {
		@InOut({ type: CubageLivraison  , isVModel: true }) private value!: CubageLivraison;
		@Stored(() => storeToken) private token: Token;

		public numfacture : string = '';
		private storeCubageLivraison = storeCubageLivraison;

		@Emit()
		private onSubmit(cubageLivraison: CubageLivraison) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.numfacture = '';
			this.value = null;
		}

		protected async onValider(): Promise<void>  {
			for (const element of this.storeCubageLivraison.state.cubageLivraisons.data)
			{
				if (element.selected === true) {
					//console.log(element.id);
					await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-livraisons/affecter_numfacture/" + element.id + "/" + (this.numfacture!=='' ? this.numfacture : 'vide') + "?token=" + this.token.id, {});
				}
			}
			this.onClose();
			//this.value = null;
			//this.numfacture = '';
		}

}
