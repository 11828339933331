



















import {Prop} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudChantierFilterForSelect from '@/components/Crud/Chantier/FilterForSelect.vue';
import CrudChantierListForSelect from '@/components/Crud/Chantier/ListForSelect.vue';

@Component({
	components: {
		CrudChantierFilterForSelect,
		CrudChantierListForSelect,
		InputSearchEntity,
	}
})
export default class CrudChantierSearch extends AbstractSearchVue {
	@Prop({ type: Array, default: () => [] }) public finDeCoupe!: boolean[];
	
	private filterComponent = CrudChantierFilterForSelect;
	private listComponent = CrudChantierListForSelect;

}
