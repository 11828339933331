import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentCubageLivraison} from '@/models';
import {DocumentCubageLivraisonService} from '@/xhttp';
import store from '@/stores';
//import cubage from "@/router/cubage";
import storeCubageLivraison from "@/stores/modules/cubageLivraison";

Vue.use(Vuex);

class DocumentCubageLivraisonState {
}

class DocumentCubageLivraisonStore {

	@XHTTPService(() => DocumentCubageLivraison)
	private documentService: DocumentCubageLivraisonService;

	public state: DocumentCubageLivraisonState = new DocumentCubageLivraisonState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentCubageLivraisonState, any>, document: DocumentCubageLivraison): Promise<DocumentCubageLivraison> {
			try {
				document.cubage.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeCubageLivraison.dispatch('get', document.cubage.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentCubageLivraisonStore();
const storeDocumentCubageLivraison = new Vuex.Store(instance);
store.registerModule('document', storeDocumentCubageLivraison);
export default storeDocumentCubageLivraison;
