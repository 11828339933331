import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {GenericService, ResultType, XHTTPService} from '@/shared/xhttp';
import {Role} from '@/models';
import {PaginatorAction, PaginatorMutation, PaginatorState} from '@/shared/utils';
import store from '@/stores';
import {SetterMutation} from '@/shared/utils/Store';

Vue.use(Vuex);

class RoleState extends PaginatorState {
	roles: ResultType<Role> = null;
	public limit: number = 100;
	public constructor() {
		super('roles');
	}
}

class RoleStore {
	
	@XHTTPService(() => Role)
	private roleService: GenericService<Role>;
	
	public state: RoleState = new RoleState();
	
	public mutations = {
		...PaginatorMutation(() => storeRole, 'roles'),
		...SetterMutation(['roles']),
	};
	
	public actions = {
		
		...PaginatorAction<Role, RoleState>(() => Role, 'roles'),
		
		async getCNoRefresh(context: ActionContext<RoleState, any>): Promise<ResultType<Role>> {
			if (context.state.roles) {
				return context.state.roles;
			}
			return context.dispatch('getC');
		}
		
	};
	
}

const instance = new RoleStore();
const storeRole = new Vuex.Store(instance);
store.registerModule('role', storeRole);
export default storeRole;
