
































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Contact} from '@/models';

import CrudContactShow from '@/components/Crud/Contact/Show.vue';

@Component({
	components: {
		CrudContactShow,
	}
})
export default class CrudContactShowDialog extends Vue {
	@InOut({ type: Contact  , isVModel: true }) private value!: Contact;
	
	@Emit()
	public onClose() {
		this.value = null;
	}
}
