
























































import {Component, Vue} from '@/shared/component';
import {InOut} from 'vue-inout-prop-decorator';
import {Emit, Prop, Watch} from 'vue-property-decorator';
import {Store} from "vuex";

import storeNotifier, {NotifyInterface} from "@/stores/modules/notifier";

@Component()
export default class CrudDeleteDialog extends Vue {
	@InOut({type: Object, isVModel: true}) private value: any;
	@Prop ({type: String, required: true}) private title: string;
	@Prop ({type: String, required: true}) private label: string;
	@Prop ({type: Store , required: true}) private store: Store<any>;
	@Prop ({type: String, required: false, default: null}) private repeatField: string;

	private repeat: string = '';

	public get confirmed(): boolean {
		//return !this.repeat || this.repeat.toLowerCase() === this.value[this.repeatField].toLowerCase();
		return this.repeat.toLowerCase() === this.value[this.repeatField].toString().toLowerCase();
	}

	@Watch('value')
	private valueWatcher(): void {
		this.repeat = '';
	}

	@Emit()
	private async onConfirm(): Promise<any> {
		try {
			const value = this.value;
			await this.store.dispatch('delete', value);
			storeNotifier.dispatch('notify', {
				type: 'success',
				message: 'Suppression réussie'
			} as NotifyInterface);
			this.onClose();

			return value;
		} catch(e) {
			if (e.responseJSON)
			{
				this.notify(e.responseJSON.message, 'error');
			}
			else
			{
				this.notify("Erreur inconnue", 'error');
			}
		}
	}

	@Emit()
	private onClose() {
		this.value = null;
	}

}
