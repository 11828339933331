





















































































import {Component} from '@/shared/component';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
import {Contact} from '@/models';

import storeContact from '@/stores/modules/contact';

import CrudAddressShow from '@/components/Crud/Address/Show.vue';
import CrudContactListReduce from '@/components/Crud/Contact/ListReduce.vue';
import CrudContratListReduce from '@/components/Crud/Contrat/ListReduce.vue';

@Component({
	components: {
		CrudAddressShow,
		CrudContactListReduce,
		CrudContratListReduce,
	}
})
export default class CrudContactShowReduce extends AbstractShowVue<Contact> {
	public constructor() {
		super(storeContact);
	}
}
