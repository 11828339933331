







































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {CubagePlateforme, Chantier} from '@/models';

	import CrudCubagePlateformeEdit from '@/components/Crud/CubagePlateforme/Edit.vue';

	@Component({
		components: {
			CrudCubagePlateformeEdit,
		}
	})
	export default class CrudCubagePlateformeEditDialog extends Vue {
		@InOut({ type: CubagePlateforme  , isVModel: true }) private value!: CubagePlateforme;

		@Emit()
		private onSubmit(cubagePlateforme: CubagePlateforme) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}

}
