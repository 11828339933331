import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize, BoisType, Chantier, CubagePrestationEstimed, Marquage, Prestation} from '@/models';
import {CubagePrestationEstimedService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class CubagePrestationEstimedFilter {

	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public boisSize:     BoisSize[] = [];
	public marquage:     Marquage[] = [];
	public prestation:   Prestation[] = [];
	public chantier:     Chantier[] = [];
	public volumeInner:  NumberFilter = new NumberFilter();
	public volumeOutter: NumberFilter = new NumberFilter();
	public coef:         NumberFilter = new NumberFilter();
	public numero:       string = '';
	
	public toString(): string {
		return JSON.stringify({
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			boisSize    : this.boisSize.map(b => b.id),
			marquage    : this.marquage.map(m => m.id),
			prestation  : this.prestation.map(m => m.id),
			chantier    : this.chantier.map(m => m.id),
			volumeInner : this.volumeInner.toJSON(),
			volumeOutter: this.volumeOutter.toJSON(),
			coef        : this.coef.toJSON(),
			numero      : this.numero,
		});
	}
}

class CubagePrestationState extends PaginatorFilterState<CubagePrestationEstimedFilter> {
	public cubagePrestationEstimed: CubagePrestationEstimed = null;
	public cubagePrestationEstimeds: ResultType<CubagePrestationEstimed> = null;
	public constructor() {
		super(new CubagePrestationEstimedFilter(), 'cubagePrestationEstimeds', 'cubagePrestationEstimed');
	}
}

class CubagePrestationStore {
	
	@XHTTPService(() => CubagePrestationEstimed)
	private cubagePrestationEstimedService: CubagePrestationEstimedService;
	
	public state: CubagePrestationState = new CubagePrestationState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeCubagePrestationEstimed, 'cubagePrestationEstimeds', 'cubagePrestationEstimed'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<CubagePrestationEstimed, CubagePrestationState>(() => CubagePrestationEstimed, 'cubagePrestationEstimeds', 'cubagePrestationEstimed'),
		...StandardAction<CubagePrestationEstimed, CubagePrestationState>(() => CubagePrestationEstimed, 'cubagePrestationEstimed'),
	};
	
}

const instance = new CubagePrestationStore();
const storeCubagePrestationEstimed = new Vuex.Store(instance);
store.registerModule('cubagePrestationEstimed', storeCubagePrestationEstimed);
export default storeCubagePrestationEstimed;
