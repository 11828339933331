
















import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudDebardeurFilter from '@/components/Crud/Debardeur/Filter.vue';
import CrudDebardeurListForSelect from '@/components/Crud/Debardeur/ListForSelect.vue';

@Component({
	components: {
		CrudDebardeurFilter,
		CrudDebardeurListForSelect,
		InputSearchEntity,
	}
})
export default class CrudDebardeurSearch extends AbstractSearchVue {
	private filterComponent = CrudDebardeurFilter;
	private listComponent = CrudDebardeurListForSelect;
}
