




























































































import {Component, Vue} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator";
import {CubageChantierRestant, User} from '@/models';
import {ResultType} from "@/shared/xhttp";

import storeCubageChantierRestant from "@/stores/modules/cubageChantierRestant";
import storeUser from '@/stores/modules/user';

import CrudCubageChantierRestantWithoutMarquageFilter from '@/components/Crud/CubageChantierRestant/FilterWithoutMarquage.vue';
import CrudCubageChantierRestantList from '@/components/Crud/CubageChantierRestant/List.vue';

import CrudCubageChantierRestantForSearchChantierList from '@/components/Crud/CubageChantierRestant/ListForSearchChantier.vue';
import CrudCubageChantierRestantForSearchChantierFilter from '@/components/Crud/CubageChantierRestant/FilterForSearchChantier.vue';

import axios from "axios";
import storeToken from "@/stores/modules/token";
import {Token} from "@/models";
import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';
import router from '@/router';
import {Emit} from 'vue-property-decorator';
import CrudChantierEditDialog from '@/components/Crud/Chantier/EditDialog.vue';
import {Chantier} from '@/models';

@Component({
	components: {
		CrudCubageChantierRestantList,
		CrudCubageChantierRestantWithoutMarquageFilter,
		CrudCubageChantierRestantForSearchChantierList,
		CrudCubageChantierRestantForSearchChantierFilter,
		CrudChantierEditDialog,
	}
})
export default class HomeView extends Vue {

	@Stored(() => storeCubageChantierRestant) cubageChantierRestants!: ResultType<CubageChantierRestant>; 
	@Stored(() => storeUser) me!: User; 
	@Stored(() => storeToken) private token: Token;

	private idlastchantier : number = 0;
	private editChantier: Chantier = null;
	private isNew: boolean = false;

	public created(): void {
		storeCubageChantierRestant.state.filters.boisType = true;
		storeCubageChantierRestant.state.filters.boisQuality = true;
		storeCubageChantierRestant.state.filters.boisSize = true;
		storeCubageChantierRestant.state.filters.nullValue = false;
		storeCubageChantierRestant.state.filters.groupChantier = false;
		storeCubageChantierRestant.state.filters.chantier = null;

		//console.log("created");
	}
	
	public mounted(): void {
		//this.addEventDom(window, 'resize', () => this.$forceUpdate());

		//console.log("mounted");
	}

	public async onChantierEncours(): Promise<void>
	{
		await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/users/last_chantier/" + this.me.id + "?token=" + this.token.id, {}).then(async response => {
			this.idlastchantier = await response.data;

			//console.log(this.idlastchantier);

			if (this.idlastchantier > 0)
			{
				this.$router.push({name: 'chantier-show', params: { id : this.idlastchantier.toString() }});
			}
			else{
				this.notify("Aucun chantier en cours");
			}
		});
	}

	/*private get points(): number[] {
		return this.cubageChantierRestants.data.map(av => av.volume);
	}
	
	private get chart_title(): string
	{
		return "Bois en stock";
	}

	private get values(): { label: string, volume: number }[] {
		return this.cubageChantierRestants.data.map(av => {
			const label = [];
			if (av.boisType) label.push(av.boisType.name);
			if (av.boisQuality) label.push(av.boisQuality.name);
			if (av.boisSize) label.push(av.boisSize.name);
			return {
				label: label.join(', '),
				montooltip: label.join(', ') + ': '+av.volume,
				volume: av.volume,
			};
		});
	}*/
	
	@Emit()
	private onCreate(): void {
		this.isNew = true;
		this.editChantier = new Chantier();
	}
}
