var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.markPayPreparedConfirm = true}}},[_vm._v(" Marquer tous paiements préparés ")])],1),_c('dialog-confirm',{attrs:{"width":"600px","value":!!_vm.markPayPreparedConfirm,"title":"Marquer comme préparée","text":"Êtes vous sûr de vouloir marquer toutes les prestations avec paiement préparé","confirm-text":"Oui","cancel-text":"Non"},on:{"input":function($event){_vm.markPayPreparedConfirm = false},"confirm":function($event){return _vm.prestationMarkPayPrepared()}}})],1),(_vm.list && _vm.isGranted('API_GET_PRESTATION'))?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list.data,"search":_vm.search,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.contrat",fn:function(ref){
var item = ref.item;
return [(item.contrat)?[_vm._v(" "+_vm._s(item.contrat.numero)),(_vm.isGranted('API_GET_CONTRAT'))?_c('router-link',{attrs:{"to":{ name: 'contrat-show', params: { id: item.contrat.id } },"no-underline":""}},[(_vm.isGranted('API_GET_CONTRAT'))?_c('v-btn',{attrs:{"icon":"","color":"grey","title":"Afficher contrat"}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()],1):_vm._e()]:[_c('i',[_vm._v("Aucun contrat associé")])]]}},{key:"item.montantRegler",fn:function(ref){
var item = ref.item;
return [(_vm.me.isOperateurOnly() == false)?_c('div',[_vm._v(_vm._s(item.montantRegler)+" €")]):_vm._e()]}},{key:"item.dtEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.dtEnd))+" ")]}},(_vm.me.isOperateurOnly() == false)?{key:"item.isPaid",fn:function(ref){
var item = ref.item;
return [(!item.payer && item.paiementPreparer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"color":"orange"}},on),[_vm._v("mdi-close")])]}}],null,true)},[_vm._v(" Non payé mais préparé ")]):_c('checked',{staticStyle:{"color":"orange"},attrs:{"value":item.payer,"label-true":"Payé","label-false":"Non payé"}})]}}:null,{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contrat.tiers.name)+" "),_c('v-btn',{attrs:{"icon":"","color":"see","title":"Afficher","to":{ name: 'tiers-show', params: { id: item.contrat.tiers.id } }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.alerte",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.traverseCoursEau || item.demandeDTT || item.autreAlerte)?_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"red"}},on),[_vm._v("mdi-alert")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.resumeAlerte))])])]}},(_vm.me.isOperateurOnly() == false)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","to":{ name: 'prestation-show', params: { id: item.id } },"title":"Afficher"}},[_c('v-icon',[_vm._v("mdi-eye")])],1),(_vm.chantier && _vm.isGranted('API_PUT_CHANTIER'))?_c('v-btn',{attrs:{"icon":"","title":"Retirer du chantier"},on:{"click":function($event){$event.stopPropagation();_vm.removePrestation = item}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}:null],null,true)}):_vm._e(),_c('dialog-confirm',{attrs:{"width":"600px","value":!!_vm.removePrestation,"title":"Retirer la prestation du chantier","text":"Êtes vous sûr de vouloir retirer la prestation du chantier","confirm-text":"Retirer"},on:{"input":function($event){_vm.removePrestation = false},"confirm":function($event){return _vm.onRemovePrestation()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }