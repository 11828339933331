







































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {BoisType} from '@/models';

import CrudBoisTypeEdit from '@/components/Crud/BoisType/Edit.vue';

@Component({
	components: {
		CrudBoisTypeEdit,
	}
})
export default class CrudBoisTypeEditDialog extends Vue {
	@InOut({ type: BoisType  , isVModel: true }) private value!: BoisType;

	@Emit()
	private onSubmit(boisType: BoisType) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
