var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isGranted('API_GET_CUBAGELIVRAISON') && _vm.list)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list.data,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.scierie",fn:function(ref){
var item = ref.item;
return [(item.scierie)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.scierie.name.slice(0, 10)+'...'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.scierie.name))])])]:[_c('i',[_vm._v("Indefini")])]]}},{key:"item.chauffeur",fn:function(ref){
var item = ref.item;
return [(item.chauffeur)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.chauffeur.fullName.slice(0, 10)+'...'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.chauffeur.fullName))])])]:[_c('i',[_vm._v("Indefini")])]]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_c('table',[_c('tr',[_c('td',[(item.volumeApproximatif)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-approximately-equal")]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.volumeInner ? item.volumeInner+'m³' : '')+" ")])])])]}},{key:"item.livrer",fn:function(ref){
var item = ref.item;
return [_c('checked',{attrs:{"value":item.boisFini,"label-true":"Tout livré","label-false":"Reste sur chantier"}})]}}],null,false,655540784)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }