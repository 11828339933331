import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Debardeur, Tiers} from '@/models';
import {DebardeurService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class DebardeurFilter {
	
	firstName: string = '';
	lastName: string = '';
	
	public clear(): void {
		this.firstName = '';
		this.lastName = '';
	}

	public toString(): string {
		return JSON.stringify({
			firstName: this.firstName,
			lastName : this.lastName,
		});
	}
}

class DebardeurState extends PaginatorFilterState<DebardeurFilter> {
	public debardeur: Debardeur = null;
	public debardeurs: ResultType<Debardeur> = null;
	public constructor() {
		super(new DebardeurFilter(), 'debardeurs', 'debardeur', 'id', AscDesc.DESC, 50);
	}
}

class DebardeurStore {
	
	@XHTTPService(() => Debardeur)
	private debardeurService: DebardeurService;
	
	public state: DebardeurState = new DebardeurState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeDebardeur, 'debardeurs', 'debardeur'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<Debardeur, DebardeurState>(() => Debardeur, 'debardeurs', 'debardeur'),
		...StandardAction<Debardeur, DebardeurState>(() => Debardeur, 'debardeur'),
	};
	
}
const instance = new DebardeurStore();
const storeDebardeur = new Vuex.Store(instance);
store.registerModule('debardeur', storeDebardeur);
export default storeDebardeur;
