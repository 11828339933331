










import {Component, Prop, Vue} from 'vue-property-decorator';
import {Form} from './Form';

@Component
export default class LoopForm extends Vue {
	@Prop({ type: Array, required: false }) list: any[];
	
	private get form(): Form {
		return Form.searchParentForm(this);
	}

	private get prefixName(): string {
		return Form.searchParentName(this);
	}

	private formName(index: number): (name: string) => string {
		return (name: string) => {
			return `${this.prefixName}[${index}].`+name;
		};
	}

	private formRules(index: number): (name: string) => ((value: any) => (string|boolean))[] {
		return (name: string, rules: ((value: any) => (string|boolean))[] = []) => {
			return this.form ? this.form.rules(this.formName(index)(name), rules) : rules;
		};
	}
}
