
























































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Chantier} from '@/models';

import CrudChantierFilter from '@/components/Crud/Chantier/Filter.vue';
import CrudChantierShowDialog from '@/components/Crud/Chantier/ShowDialog.vue';
import CrudChantierEditDialog from '@/components/Crud/Chantier/EditDialog.vue';
import CrudChantierList from '@/components/Crud/Chantier/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
import CrudChantierRapideEditDialog from '@/components/Crud/Chantier/EditChantierRapideDialog.vue';

import storeChantier from '@/stores/modules/chantier';

@Component({
	components: {
		CrudChantierFilter,
		CrudChantierShowDialog,
		CrudChantierEditDialog,
		CrudChantierList,
		CrudDeleteDialog,
		CrudChantierRapideEditDialog,
	}
})
export default class ChantierView extends Vue {
	
	@Stored(() => storeChantier)
	private chantiers!: ResultType<Chantier>;
	
	private showChantier: Chantier = null;
	private editChantier: Chantier = null;
	private editChantierRapide: Chantier = null;
	private deletedChantier: Chantier = null;
	private storeChantier = storeChantier;
	private isNew: boolean = false;
	
	@Emit()
	private onCreate(): void {
		this.isNew = true;
		this.editChantier = new Chantier();
	}
	
	@Emit()
	private onCreateChantierRapide(): void {
		this.isNew = true;
		console.log("New chantier + contrat");
		this.editChantierRapide = new Chantier();
		this.editChantierRapide.name = 'CH-RAPIDE';
	}

	private refresh(): void {
		this.isNew = false;
		storeChantier.dispatch('getC', { filters: true });
	}
}
