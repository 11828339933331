







































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {DateFilter} from '@/shared/filter';

import storeContact, {ContactFilter} from '@/stores/modules/contact';

import InputDateFilter from '@/components/Input/DateFilter.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

@Component({
	components: {
		InputDateFilter,
		CrudTiersSearch,
	}
})
export default class CrudContactFilter extends Vue {

	@Prop({ type: Boolean, default: false}) reduce;

	@Stored(() => storeContact) private page!: number;
	@Stored(() => storeContact) private filters!: ContactFilter;

	@WatchObjectProperties('filters', ContactFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeContact.dispatch('getC', { filters: true });
	}

	public mounted() {
		if (this.reduce) {
			this.filters.tiers = [];
		}
	}
}
