import {Native} from "@/shared/utils";

export default (v: string) =>
	Native.empty(v) ||
	(
		v.length >= 8 &&
		!!v.match(/[0-9]/) &&
		!!v.match(/[a-z]/) &&
		!!v.match(/[A-Z]/)
	) ||
	'Le mot de passe doit posséder 8 charactères minimum majuscule et chiffre'
