





























































import {Component} from '@/shared/component';
import {Debardeur} from '@/models';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';

import storeDebardeur from '@/stores/modules/debardeur';

import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';

@Component({
	components: {
		CrudTiersShow,
		CrudTiersEdit,
	}
})
export default class CrudDebardeurShow extends AbstractShowVue<Debardeur> {
	public constructor() {
		super(storeDebardeur);
	}
}
