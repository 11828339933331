
























































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, BoisQuality, BoisSize, BoisType, Chantier, CubagePlateforme, CubageChantier, Marquage} from '@/models';
	import {ObjectHelper} from "@/shared/utils";
	import InputDatePicker from '@/components/Input/DatePicker.vue';

	import storeCubagePlateforme from '@/stores/modules/cubagePlateforme';
	import storeCubageChantier from '@/stores/modules/cubageChantier';

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import CrudChantierSearch from '@/components/Crud/Chantier/Search.vue';
	import boisQuality from '@/router/boisQuality';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';

	@Component({
		components: {
			InputDatePicker,
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			CrudChantierSearch,
			CrudTiersSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudCubagePlateformeEdit extends AbstractEditVue<CubagePlateforme> {
		
		private TiersType = TiersType;
		private savedisabled: Boolean = true;

		public constructor() {
			super(
				storeCubagePlateforme,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		protected async refresh(): Promise<void> {
			await super.refresh();

			this.watcher();
		}

		private onChangeVolumeInner(): void {
			if (this.item.volumeInner) {
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeOutter(): void {
			if (this.item.volumeOutter) {
				if (this.item.coef) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeStere(): void {
			if (this.item.volumeStere) {
				if (this.item.coefStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeCoef(): void {
			if (this.item.coef) {
				if (this.item.volumeOutter) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onChangeCoefStere(): void {
			if (this.item.coefStere) {
				if (this.item.volumeStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		public watcher(): void {
		}
	}
