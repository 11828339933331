












































































































































































































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, BoisQuality, BoisSize, BoisType, Chantier, Token, CubageLivraison, CubageChantier, Marquage} from '@/models';
	import {ObjectHelper} from "@/shared/utils";
	import InputDatePicker from '@/components/Input/DatePicker.vue';

	import storeCubageLivraison from '@/stores/modules/cubageLivraison';
	import storeCubageChantier from '@/stores/modules/cubageChantier';
	import storeChantier from '@/stores/modules/chantier';
	import storeLivraison from '@/stores/modules/livraison';

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import CrudChantierSearchForLivraison from '@/components/Crud/Chantier/SearchForLivraison.vue';
	import boisQuality from '@/router/boisQuality';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
	import axios from "axios";
	import storeToken from "@/stores/modules/token";
	import {Stored} from "vue-stored-prop-decorator";

	@Component({
		components: {
			InputDatePicker,
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			storeLivraison,
			CrudChantierSearchForLivraison,
			CrudTiersSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudCubageLivraisonEditCreate extends AbstractEditVue<CubageLivraison> {
		
		@Stored(() => storeToken) private token: Token;
		private TiersType = TiersType;
		private savedisabled: Boolean = true;
		private showMessage: Boolean = false;
		private TypeBoisVisible: Boolean = false;
		private storeLivraison = storeLivraison;
		public SelectedBoisChantier: object = null;
		public BoisDispo = [
			{identifiant: 1, text: 'Vide',boistype : '', boisQuality : '', boisSize : '', marquage : ''}
		];

		public constructor() {
			super(
				storeCubageLivraison,
				'Cubage modifié',
				'Cubage créé',
			);

		}

		protected async refresh(): Promise<void> {
			await super.refresh();

			this.watcher();
		}
		
		public UpdateUI()
		{
			this.$forceUpdate();
		}

		public watcher(): void {
			if (this.item)
			{
				if	(this.item.prixM3 > 500) {
					this.savedisabled = true
					this.showMessage = true;
				} else {
					this.savedisabled = !this.item.chauffeur || !this.item.scierie;
					this.showMessage = false;
				}
			}
		}

		public submit(): void
		{
			storeLivraison.state.nextcubage_meme_chantier = false;
			storeLivraison.state.nextcubage_autre_chantier = false;
		}

		public AutreChantier() : void{
			storeLivraison.state.nextcubage_autre_chantier = true;
		}

		public MemeChantier() : void{
			storeLivraison.state.nextcubage_meme_chantier = true;
			storeLivraison.state.PreviousCubageLivraison = this.item;
		}

		public watcherSave(): void {
			if (this.item)
			{
				if	(this.item.prixM3 > 500) {
					this.savedisabled = true
					this.showMessage = true;
				} else {
					this.savedisabled = false;
					this.showMessage = false;
				}
			}
		}

		@Watch('item.chantier')
		public async ChangeChantier(): Promise<void> {
			var oChantier = await storeChantier.dispatch('get', this.item.chantier.id);

			this.LoadBoisDispo();
		}

		private LoadBoisDispo()
		{
			this.BoisDispo = [];

			if (storeChantier.state.chantier)
			{
				storeChantier.state.chantier.cubageChantiers.forEach((value, index) => {
					var strText = "";
					if (value.boisType)
					{
						strText += value.boisType.name;
					}
					if (value.boisQuality)
					{
						strText += "|" + value.boisQuality.name;
					}
					if (value.boisSize)
					{
						strText += "|" + value.boisSize.name;
					}
					if (value.marquage)
					{
						strText += "|" + value.marquage.name;
					}

					this.BoisDispo.push({ identifiant: value.boisType.id ,  text : strText ,  boistype : value.boisType, 
						boisQuality : value.boisQuality, boisSize : value.boisSize, marquage : value.marquage });
   				});

			}
		}

		private async onSelectBoisChantier() : Promise<void>  {
			console.log(this.SelectedBoisChantier);

			if (this.SelectedBoisChantier["marquage"] == null)
			{
				this.item.sansMarquage = true;
				this.item.marquage = null;
				this.item.boisType = this.SelectedBoisChantier["boistype"]
				this.item.boisQuality = this.SelectedBoisChantier["boisQuality"]
				this.item.boisSize = this.SelectedBoisChantier["boisSize"]
			}
			else
			{
				this.item.sansMarquage = false;
				this.item.marquage = this.SelectedBoisChantier["marquage"]

				await this.onMarquageChange();
			}

			this.$forceUpdate();
		}

		private async getVolumeRestant(): Promise<void> 
		{
			var result: string = '';
			if (this.item.chantier != null)
			{
			    await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantier-restants/get_reste/" + this.item.chantier.id + "/" + this.item.boisType.id + "/" +  this.item.boisQuality.id + "/" + this.item.boisSize.id + "/" + this.item.marquage.id + "?token=" + this.token.id, {}).then(async response => {
					result = await response.data;
				});

				//console.log(result);
				this.item.volumeEstimeRestant = result;
			}
			else
			{
				this.item.volumeEstimeRestant = 'Inconnu';
			}
		}

		private async onMarquageChange(): Promise<void>  {
			var boisVisible = true;
			if (this.item.chantier != null)
			{
				var oChantier = await storeChantier.dispatch('get', this.item.chantier.id);

				//console.log(oChantier);
				this.LoadBoisDispo();
				oChantier.cubageChantiers.forEach((value, index) => {
					if ((value.marquage) && (value.marquage.name === this.item.marquage.name))
					{
						boisVisible = false;
						this.item.boisType = value.boisType;
						this.item.boisQuality = value.boisQuality;
						this.item.boisSize = value.boisSize;
					}
   				});

				this.getVolumeRestant();
			}

			this.TypeBoisVisible = boisVisible;
		}

		private getFormattedDate(dateString): String{
			if (dateString == '') return '';
			if (dateString == null) return '';

            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { month: 'long', day: 'numeric', year: 'numeric', }).format(date);
		}
		
}
