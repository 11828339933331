









































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {PrestationType} from '@/models';

import storePrestationType from '@/stores/modules/prestationType';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

@Component({
	components: {
		InputDatePicker,
		CrudTiersSearch,
	}
})
export default class CrudPrestationTypeEdit extends AbstractEditVue<PrestationType> {
	public constructor() {
		super(
			storePrestationType,
			'Type de prestation modifié',
			'Type de prestation créé',
		);
	}
}
