








































	import {Emit, Prop} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	
	import {Chantier} from '@/models';
	
	import CrudChantierEditPlanning from '@/components/Crud/Chantier/EditPlanning.vue';
	
	@Component({
		components: {
			CrudChantierEditPlanning,
		}
	})
	export default class CrudChantierEditDialogPlanning extends Vue {
		@InOut({ type: Chantier  , isVModel: true }) private value!: Chantier;
		@Prop({ type: Boolean, default: false}) showOnClose: boolean;
        @Prop({ type: Boolean, default: false}) noRefresh: boolean;

		@Emit()
		private onSubmit(chantier: Chantier) {
			if (this.showOnClose) {
				this.$router.push({name: 'chantier-show', params: { id : this.value.id.toString() }});
			}
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}
	}
