







































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {BoisQuality} from '@/models';

import CrudBoisQualityEdit from '@/components/Crud/BoisQuality/Edit.vue';

@Component({
	components: {
		CrudBoisQualityEdit,
	}
})
export default class CrudBoisQualityEditDialog extends Vue {
	@InOut({ type: BoisQuality  , isVModel: true }) private value!: BoisQuality;

	@Emit()
	private onSubmit(boisQuality: BoisQuality) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
