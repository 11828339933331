



















import {Prop} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudChantierFilterForLivraison from '@/components/Crud/Chantier/FilterForLivraison.vue';
import CrudChantierListForSelect from '@/components/Crud/Chantier/ListForSelect.vue';

@Component({
	components: {
		CrudChantierFilterForLivraison,
		CrudChantierListForSelect,
		InputSearchEntity,
	}
})
export default class CrudChantierSearchForLivraison extends AbstractSearchVue {
	@Prop({ type: Array, default: () => [] }) public finDeCoupe!: boolean[];
	
	private filterComponent = CrudChantierFilterForLivraison;
	private listComponent = CrudChantierListForSelect;

}
