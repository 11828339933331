






































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {Chantier} from "@/models";
import {AscDesc} from "@/shared/xhttp";

import storeCubageChantierRestant, {CubageChantierRestantFilter} from "@/stores/modules/cubageChantierRestant";
import storeChantier from "@/stores/modules/chantier";

@Component({
	components: {
	}
})
export default class CrudCubageChantierRestantFilter extends Vue {

	@Prop({ type: Boolean, default: false }) private reduce;

	@Stored(() => storeCubageChantierRestant) private filters!: CubageChantierRestantFilter;
	@Stored(() => storeCubageChantierRestant) private order!: string;
	@Stored(() => storeCubageChantierRestant) private limit!: number;
	@Stored(() => storeCubageChantierRestant) private page!: number;
	@Stored(() => storeCubageChantierRestant) private direction!: AscDesc;

	@Stored(() => storeChantier) private chantier!: Chantier;

	@Watch('showChantier')
	public mounted(): void {
		this.filters.marquage = false
		this.filters.groupChantier = false;
	}

	private get methodName(): string {
		return 'getC';
	}

	@WatchObjectProperties('filters', CubageChantierRestantFilter)
	@Watch('chantier')
	@Watch('limit')
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		this.refresh();
	}

	@Watch('order')
	@Watch('page')
	@Watch('direction')
	private refresh(): void {
		storeCubageChantierRestant.dispatch(this.methodName, { filters: true });
	}
}
