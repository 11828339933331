



























































































































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Purchase} from '@/models';
import {ResultType} from "@/shared/xhttp";

import InputDatePicker from '@/components/Input/DatePicker.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
import {PurchasePrice} from "@/models/PurchasePrice";
import validator from "@/shared/validator";
import {Stored} from "vue-stored-prop-decorator/index";
import storeLoader from "@/stores/modules/loader";
import storePurchase from "@/stores/modules/purchase";

@Component({
	components: {
		InputDatePicker,
		CrudTiersSearch,
	}
})
export default class CrudPurchaseEditPrice extends Vue {
	
	@Prop({ type: Purchase, required: true })public purchase: Purchase;
	
	@Stored(() => storeLoader) loading!: boolean;
	
	private validator = validator;
	
	private precisionType: boolean = false;
	private precisionQuality: boolean = false;
	private precisionSize: boolean = false;
	
	private prices: PurchasePrice[] = [];
	
	public async mounted(): Promise<void> {
		//this.averageVolumes = await storeAverageVolume.dispatch('getCPurchase', this.purchase);
		for (const price of this.purchase.purchasePrices) {
			if (price.boisType) {
				this.precisionType = true;
			}
			if (price.boisQuality) {
				this.precisionQuality = true;
			}
			if (price.boisSize) {
				this.precisionSize = true;
			}
		}
		this.build();
	}
	
	public get formValid(): boolean {
		for (const price of this.prices) {
			if (
				price.price === null ||
				price.tva === null
			) {
				return false;
			}
		}
		return true;
	}
	
	@Watch('precisionType')
	@Watch('precisionQuality')
	@Watch('precisionSize')
	public build(): void {
		this.prices = [];
		/*const search = (averageVolume: AverageVolume): PurchasePrice => {
			for (const price of this.prices) {
				if (!this.precisionType) {
					return price;
				}
				if (price.boisType.id !== averageVolume.boisType.id) {
					continue;
				}
				
				if (!this.precisionQuality) {
					return price;
				}
				if (price.boisQuality.id !== averageVolume.boisQuality.id) {
					continue;
				}
				
				if (!this.precisionSize) {
					return price;
				}
				if (price.boisSize.id !== averageVolume.boisSize.id) {
					continue;
				}
				return price;
			}
			return null;
		};
		
		for (const averageVolume of this.averageVolumes.data) {
			let price = search(averageVolume);
			if (!price) {
				price = new PurchasePrice();
				price.volume = null;
				if (this.precisionType) {
					price.boisType = averageVolume.boisType;
					if (this.precisionQuality) {
						price.boisQuality = averageVolume.boisQuality;
						if (this.precisionSize) {
							price.boisSize = averageVolume.boisSize;
						}
					}
				}
				
				for (const pprice of this.purchase.purchasePrices) {
					if (
						(!price.boisType    && !pprice.boisType   ) || (pprice.boisType    && price.boisType    && pprice.boisType.id    === price.boisType.id   ) &&
						(!price.boisQuality && !pprice.boisQuality) || (pprice.boisQuality && price.boisQuality && pprice.boisQuality.id === price.boisQuality.id) &&
						(!price.boisSize    && !pprice.boisSize   ) || (pprice.boisSize    && price.boisSize    && pprice.boisSize.id    === price.boisSize.id   )
					) {
						price.price = pprice.price;
						price.tva = pprice.tva;
					}
				}
				this.prices.push(price); 
			}
			price.volume += averageVolume.volume;
		}*/
	}
	
	private async save(): Promise<void> {
		try {
			this.purchase.purchasePrices = this.prices;
			await storePurchase.dispatch('patchPrices', this.purchase);
			this.$emit('save');
		} catch(e) {
			this.notify('Une erreur est survenue', 'error');
		}
	}

}
