

















































import {Component, Vue} from '@/shared/component';
import {Prop, Watch} from "vue-property-decorator";
import {InOut} from "vue-inout-prop-decorator/index";
import {ObjectString} from "gollumts-objecttype/index";

@Component()
export default class TokenRights extends Vue {

	@InOut({ type: Array, isVModel: true }) public value: string[];
	@Prop({ type: Array, required: true }) public rights: string[];
	@Prop({ type: Boolean, default: false }) public disabled: boolean;
	
	public mounted(): void {
		if (!this.value) {
			this.value = this.rights;
		}
	}
	
	public get valueFinal(): string[] {
		return this.value ? this.value : this.rights;
	}
	
	public set valueFinal(value: string[]) {
		this.value = value;
	}

	private get sortedRighs(): ObjectString<ObjectString<ObjectString<string>>> {
		const sortedRighs: ObjectString<ObjectString<ObjectString<string>>> = {};
		for (const right of this.rights) {
			const split = right.split(/_/g);
			if (!sortedRighs[split[0]]) sortedRighs[split[0]] = {};
			if (!sortedRighs[split[0]][split[2]]) sortedRighs[split[0]][split[2]] = {};
			sortedRighs[split[0]][split[2]][split[1]] = right;
		}
		return sortedRighs;
	}
	
	private changeGroup(groupName, entityName, value) {
		const rights = this.rights.filter(right => right.split(/_/g)[0] === groupName && right.split(/_/g)[2] === entityName);
		if (value) {
			for (const right of rights) {
				if (this.value.indexOf(right) === -1) {
					this.value.push(right);
				}
			}
		} else {
			for (const right of rights) {
				const index = this.value.indexOf(right);
				if (index !== -1) {
					this.value.splice(index, 1);
				}
			}
		}
	}

	private partialSelected(groupName: string, entityName: string): boolean {
		const splits = this.value.map(v => v.split(/_/g));
		return !!splits.filter(split => split[0] === groupName && split[2] === entityName).length;
	}

	private allSelected(groupName, entityName): boolean {
		const splitsV = this.value.map(v => v.split(/_/g)).filter(split => split[0] === groupName && split[2] === entityName);
		const splitsR = this.rights.map(v => v.split(/_/g)).filter(split => split[0] === groupName && split[2] === entityName);
		return splitsV.length === splitsR.length;
	}
	
}
