import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentChantier} from '@/models';
import {DocumentChantierService} from '@/xhttp';
import store from '@/stores';
import chantier from "@/router/chantier";
import storeChantier from "@/stores/modules/chantier";

Vue.use(Vuex);

class DocumentChantierState {
}

class DocumentChantierStore {

	@XHTTPService(() => DocumentChantier)
	private documentService: DocumentChantierService;

	public state: DocumentChantierState = new DocumentChantierState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentChantierState, any>, document: DocumentChantier): Promise<DocumentChantier> {
			try {
				document.chantier.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeChantier.dispatch('get', document.chantier.id);
				throw e;
			}
		},
		async delete_photo(context: ActionContext<DocumentChantierState, any>, document: DocumentChantier): Promise<DocumentChantier> {
			try {
				document.chantier2.removePhotos(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeChantier.dispatch('get', document.chantier2.id);
				throw e;
			}
		},
		async delete_documentChauffeur(context: ActionContext<DocumentChantierState, any>, document: DocumentChantier): Promise<DocumentChantier> {
			try {
				document.chantier3.removeDocumentChauffeurs(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeChantier.dispatch('get', document.chantier3.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentChantierStore();
const storeDocumentChantier = new Vuex.Store(instance);
store.registerModule('document', storeDocumentChantier);
export default storeDocumentChantier;
