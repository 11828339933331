






































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Debardeur} from '@/models';

import storeDebardeur from '@/stores/modules/debardeur';

import InputPhone from '@/components/Input/Phone.vue';

@Component({
	components: {
		InputPhone,
	}
})
export default class CrudDebardeurEdit extends AbstractEditVue<Debardeur> {
	public constructor() {
		super(
			storeDebardeur,
			'Debardeur modifié',
			'Debardeur créé',
		);
	}
}
