















































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Contact} from '@/models';

import CrudContactFilter from '@/components/Crud/Contact/Filter.vue';
import CrudContactShowDialog from '@/components/Crud/Contact/ShowDialog.vue';
import CrudContactEditDialog from '@/components/Crud/Contact/EditDialog.vue';
import CrudContactList from '@/components/Crud/Contact/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeContact, {ContactFilter} from '@/stores/modules/contact';

@Component({
	components: {
		CrudContactFilter,
		CrudContactShowDialog,
		CrudContactEditDialog,
		CrudContactList,
		CrudDeleteDialog,
	}
})
export default class ContactView extends Vue {
	
	@Stored(() => storeContact)
	private contacts!: ResultType<Contact>;
	
	@Stored(() => storeContact)
	private filters!: ContactFilter;

	private showContact: Contact = null;
	private editContact: Contact = null;
	private deletedContact: Contact = null;
	private storeContact = storeContact;

	@Emit()
	private onCreate(): void {
		this.editContact = new Contact();
	}

	public mounted(): void {
		console.log("Mounted contact");
		this.filters.clear();
	}

	private refresh(): void {
		storeContact.dispatch('getC', { filters: true });
	}
}
