





































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Token} from '@/models';

import CrudTokenEdit from '@/components/Crud/Token/Edit.vue';

@Component({
	components: {
		CrudTokenEdit,
	}
})
export default class CrudTokenEditDialog extends Vue {
	@InOut({ type: Token  , isVModel: true }) private value!: Token;

	@Emit()
	private onSubmit(token: Token) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
