

































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {BoisQuality} from '@/models';

import CrudBoisQualityFilter from '@/components/Crud/BoisQuality/Filter.vue';
import CrudBoisQualityEditDialog from '@/components/Crud/BoisQuality/EditDialog.vue';
import CrudBoisQualityList from '@/components/Crud/BoisQuality/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeBoisQuality from '@/stores/modules/boisQuality';

@Component({
	components: {
		CrudBoisQualityFilter,
		CrudBoisQualityEditDialog,
		CrudBoisQualityList,
		CrudDeleteDialog,
	}
})
export default class BoisQualityView extends Vue {
	
	@Stored(() => storeBoisQuality)
	private boisQualitys!: ResultType<BoisQuality>;
	
	private editBoisQuality: BoisQuality = null;
	private deletedBoisQuality: BoisQuality = null;
	private storeBoisQuality = storeBoisQuality;
	
	@Emit()
	private onCreate(): void {
		this.editBoisQuality = new BoisQuality();
	}

	private refresh(): void {
		storeBoisQuality.dispatch('getC', { filters: true });
	}
}
