import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {Plateforme, Purchase, Tiers} from '@/models';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize, BoisType, Chantier, CubagePlateforme, Marquage} from '@/models';
import {CubagePlateformeService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class CubagePlateformeFilter {

	public plateforme:   Plateforme[] = [];
	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public boisSize:     BoisSize[] = [];
	public marquage:     Marquage[] = [];
	public chantier:     Chantier[] = [];
	public numero:       string = '';

	public clear(): void {
		this.plateforme = [];
		this.boisType = [];
		this.chantier = [];
		this.boisQuality = [];
		this.boisSize = [];
		this.marquage = [];
		this.chantier = [];
		this.numero = '';
	}

	public toString(): string {
		return JSON.stringify({
			plateforme    : this.plateforme.map(b => b.id),
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			boisSize    : this.boisSize.map(b => b.id),
			marquage    : this.marquage.map(m => m.id),
			chantier    : this.chantier.map(c => c.id),
			numero      : this.numero,
		});
	}
}

class CubagePlateformeState extends PaginatorFilterState<CubagePlateformeFilter> {
	public cubagePlateforme: CubagePlateforme = null;
	public cubagePlateformes: ResultType<CubagePlateforme> = null;
	public constructor() {
		super(new CubagePlateformeFilter(), 'cubagePlateformes', 'cubagePlateforme', 'id', AscDesc.DESC, 20);
	}
}

class CubagePlateformeStore {

	@XHTTPService(() => CubagePlateforme)
	private cubagePlateformeService: CubagePlateformeService;
	private  _store: Store<any>;

	public state: CubagePlateformeState = new CubagePlateformeState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'cubagePlateformes', 'cubagePlateforme'),
	};

	public actions = { 
		...PaginatorActionWithFilters<CubagePlateforme, CubagePlateformeState>(() => CubagePlateforme, 'cubagePlateformes', 'cubagePlateforme'),
		...StandardAction<CubagePlateforme, CubagePlateformeState>(() => CubagePlateforme, 'cubagePlateforme'),
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new CubagePlateformeStore();
const storeCubagePlateforme = new Vuex.Store(instance);
instance.init(storeCubagePlateforme);

const instanceForCubageChantier = new CubagePlateformeStore();
export const storeCubagePlateformeForCubageChantier = new Vuex.Store(instanceForCubageChantier);
instanceForCubageChantier.init(storeCubagePlateformeForCubageChantier);

store.registerModule('cubagePlateforme', storeCubagePlateforme);
store.registerModule('cubagePlateformeForCubageChantier', storeCubagePlateformeForCubageChantier);
export default storeCubagePlateforme;

