

































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {PrestationType} from '@/models';

import CrudPrestationTypeFilter from '@/components/Crud/PrestationType/Filter.vue';
import CrudPrestationTypeEditDialog from '@/components/Crud/PrestationType/EditDialog.vue';
import CrudPrestationTypeList from '@/components/Crud/PrestationType/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storePrestationType from '@/stores/modules/prestationType';

@Component({
	components: {
		CrudPrestationTypeFilter,
		CrudPrestationTypeEditDialog,
		CrudPrestationTypeList,
		CrudDeleteDialog,
	}
})
export default class PrestationTypeView extends Vue {
	
	@Stored(() => storePrestationType)
	private prestationTypes!: ResultType<PrestationType>;
	
	private editPrestationType: PrestationType = null;
	private deletedPrestationType: PrestationType = null;
	private storePrestationType = storePrestationType;
	
	@Emit()
	private onCreate(): void {
		this.editPrestationType = new PrestationType();
	}

	private refresh(): void {
		storePrestationType.dispatch('getC', { filters: true });
	}
}
