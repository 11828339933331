
























































	import {Prop, Watch, PropSync} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {CubageChantier, CubageLivraison, Chantier} from "@/models";
	import {ArrayHelper} from "@/shared/utils";
  	import Checked from '@/components/Checked.vue';

	import {storeCubageLivraisonForCubageChantier, CubageLivraisonFilter} from '@/stores/modules/cubageLivraison';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';
	import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';

	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
			Checked,
		}
	})
	export default class CrudCubageLivraisonListForCubageChantier extends Vue {

		@Prop({ type: Boolean   , default: true  }) public paginate!: boolean;
		@Prop({ type: Boolean   , default: false }) public dense!   : boolean;
		@Prop({ type: [ CubageChantier, Boolean ] , default: false }) public cubageChantier: CubageChantier;
		@Prop({ type: [ Chantier, Boolean ] , default: false }) public chantier: Chantier;

		@Stored(() => storeCubageLivraisonForCubageChantier) private filters!: CubageLivraisonFilter;

		public get list(): ResultType<CubageLivraison> { return this.storeCubageLivraisonForCubageChantier.state['cubageLivraisons']; }

		private storeCubageLivraisonForCubageChantier = storeCubageLivraisonForCubageChantier;
		private headers = [];
		
		public constructor() {
			super();

			this.headers = [
			{ text: 'Date', align: 'start', value: 'date' },
			{ text: 'Scierie', value: 'scierie' },
			{ text: 'Chauffeur', value: 'chauffeur' },
			{ text: 'BL', value: 'bl' },
			{ text: 'Volume', value: 'volume'},
			{ text: 'Tout Livré', value: 'livrer' },
			];
		}
		
		public get show(): boolean {
			return this.cubageChantier !== null;
		}

		public mounted(): void {
			this.watcher();

			console.log(storeCubageLivraisonForCubageChantier);
		}

		@Watch('cubageChantier')
		public async watcher(): Promise<void>  {
			if (this.cubageChantier) {
				this.storeCubageLivraisonForCubageChantier.state.filters.chantier = [ this.chantier ];
				this.storeCubageLivraisonForCubageChantier.state.filters.boisType = [ this.cubageChantier.boisType ];
				this.storeCubageLivraisonForCubageChantier.state.filters.boisQuality = [ this.cubageChantier.boisQuality ];
				this.storeCubageLivraisonForCubageChantier.state.filters.boisSize = [ this.cubageChantier.boisSize ];

				await this.storeCubageLivraisonForCubageChantier.dispatch('getC', { filters: true });
			} else {
				this.storeCubageLivraisonForCubageChantier.state.filters.clear();
				await this.storeCubageLivraisonForCubageChantier.dispatch('getC', { filters: true });
			}
		}
	}
