




























































































































































































































































































































import {Component} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator";
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
import {Purchase, Token} from '@/models';
import {ResultType} from "@/shared/xhttp";

import storePurchase from '@/stores/modules/purchase';
import storeLoader from "@/stores/modules/loader";

import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';
import Checked from '@/components/Checked.vue';
import CrudTiersShowReduce from '@/components/Crud/Tiers/ShowReduce.vue';
import CrudPurchaseShowGeneral from "@/components/Crud/Purchase/ShowGeneral.vue";
import CrudPurchaseEditPrice from "@/components/Crud/Purchase/EditPrice.vue";
import DialogConfirm from "@/components/Dialog/Confirm.vue";
import storeToken from "@/stores/modules/token";
import {VueHelper} from "@/shared/utils";


@Component({
	components: {
		CrudTiersShow,
		CrudTiersEdit,
		Checked,
		CrudTiersShowReduce,
		DialogConfirm,
		CrudPurchaseShowGeneral,
		CrudPurchaseEditPrice,
	}
})
export default class CrudPurchaseShow extends AbstractShowVue<Purchase> {

	@Stored(() => storeLoader)    private loading: boolean;
	@Stored(() => storeToken)     private token: Token;
	
	private confirmEnd: boolean = false;
	private confirmRefund: boolean = false;

	private editPrice: boolean = false;
	
	private loadingEnd: boolean = false;

	private get hrefPdf(): string {
		return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/purchase/${this.item.id}?token=${this.token.id}`;
	}

	public constructor() {
		super(storePurchase);
	}

	public created(): void {
		/*storeAverageVolume.state.filters.boisType = false;
		storeAverageVolume.state.filters.boisQuality = false;
		storeAverageVolume.state.filters.boisSize = false;
		storeAverageVolume.state.filters.groupPrestation = false;*/
	}

	private async sendEnd(): Promise<void> {
		this.loadingEnd = true;
		try {
			this.item = await storePurchase.dispatch('patchEnd', this.item);
			VueHelper.recursiveForceUpdate(this);
		} catch (e) {
		}
		this.loadingEnd = false;
	}

	private async sendRefund(): Promise<void> {
		this.loadingEnd = true;
		try {
			this.item = await storePurchase.dispatch('patchRefund', this.item);
			VueHelper.recursiveForceUpdate(this);
		} catch (e) {
		}
		this.loadingEnd = false;
	}
	
	private async addLivraison(): Promise<void> {
		storeLoader.commit('push');
		try {
			/*for (let livraison of this.addLivraisonList) {
				livraison = await storeLivraison.dispatch('get', livraison.id);
				livraison.purchase = this.item;
				console.log(livraison.purchase);
				await storeLivraison.dispatch('put', livraison);
			}
			await Promise.all([
				storeLivraison.dispatch('getC', { filters: true }),
				storePurchase.dispatch('get', this.item.id),
			]);
			this.addLivraisonList = [];
			this.addLivraisonDialog = false;*/
		} catch (e) {
			console.error(e);
			this.notify('Une erreur est survenu', 'error');
		}
		storeLoader.commit('pop');
	}
	
	private async removeLivraison(): Promise<void> {
		storeLoader.commit('push');
		try {
			/*const removeLivraisonConfirm = await storeLivraison.dispatch('get', this.removeLivraisonConfirm.id);
			removeLivraisonConfirm.purchase = null;
			await storeLivraison.dispatch('put', removeLivraisonConfirm);
			await Promise.all([
				storeLivraison.dispatch('getC', { filters: true }),
				storePurchase.dispatch('get', this.item.id),
			]);
			this.removeLivraisonConfirm = null;*/
		} catch (e) {
			this.notify('Une erreur est survenu', 'error');
		}
		storeLoader.commit('pop');
	}
}
