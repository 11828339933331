




























	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {Chantier, Prestation} from '@/models';

	import storeChantier from '@/stores/modules/chantier';

	import InputDatePicker from '@/components/Input/DatePicker.vue';
	import CrudContactSearch from '@/components/Crud/Contact/Search.vue';
  import {Watch} from 'vue-property-decorator';
  import storePrestation from '@/stores/modules/prestation';

	@Component({
		components: {
			InputDatePicker,
			CrudContactSearch,
		}
	})
	export default class CrudChantierEditFinancier extends AbstractEditVue<Chantier> {

		public constructor() {
			super(
				storeChantier,
				'Chantier modifié',
				'Chantier créé',
			);
		}
	}
