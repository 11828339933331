

























































import {Component, Vue} from '@/shared/component';
import {Emit, Prop} from "vue-property-decorator";
import {Stored} from "vue-stored-prop-decorator";
import {DocumentChantier, Media, Token} from "@/models";

import storeToken from "@/stores/modules/token";
import storeDocumentChantier from "@/stores/modules/documentChantier";

@Component()
export default class CrudDocumentChantierShowPhoto extends Vue {
	@Prop({ type: DocumentChantier, required: true }) private document!: DocumentChantier;
	
	@Stored(() => storeToken) private token!: Token;
	private confirm: boolean = false;

	private get media(): Media {
		return this.document.media;
	}

	private get href(): string {
		return this.media.hrefs['original']+'?token='+this.token.id;
	}

	private get img(): string {
		if (this.media.type.indexOf('image/') === 0) {
			return 'image';
		}
		if (this.media.type.indexOf('video/') === 0) {
			return 'video';
		}
		
		switch (this.media.type) {
			case 'application/pdf':
			case 'application/xpdf': 
				return 'pdf';
			case 'text/csv':
			case 'application/vnd.ms-excel':
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			case 'application/vnd.oasis.opendocument.spreadsheet':
				return 'calc';
			case 'application/msword':
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 
			case 'application/vnd.oasis.opendocument.text': 
				return 'doc';
		}
		
		return 'file';
	}
	
	@Emit()
	private onDelete(): void {
		this.confirm = false;
		storeDocumentChantier.dispatch('delete_photo', this.document);
	}
}
