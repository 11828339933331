



































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storePrestationType, {PrestationTypeFilter} from '@/stores/modules/prestationType';

import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';

@Component({
	components: {
		TrueFalseMultiSelect,
	}
})
export default class CrudPrestationTypeFilter extends Vue {
	
	@Prop({ type: Boolean, default: false}) reduce;
	
	@Stored(() => storePrestationType) private page!: number;
	@Stored(() => storePrestationType) private filters!: PrestationTypeFilter;
	
	@WatchObjectProperties('filters', PrestationTypeFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storePrestationType.dispatch('getC', { filters: true });
	}
	
}
