


















































import {Component} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator/index";
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Token} from '@/models';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import TokenRight from '@/components/Input/TokenRight.vue';

import storeToken from '@/stores/modules/token';

@Component({
	components: {
		InputDatePicker,
		TokenRight,
	}
})
export default class CrudTokenEdit extends AbstractEditVue<Token> {

	@Stored(() => storeToken) private token!: Token;
	
	public constructor() {
		super(
			storeToken,
			'API Token modifié',
			'API Token créé',
		);
	}
	
	private expiration: Date = null;
	
	private get tomorrow(): Date {
		var tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		return tomorrow;
	}
	
	public async onSubmit(): Promise<void> {
		if (this.expiration) {
			this.item.lifetime = Math.ceil((this.expiration.getTime() - (new Date()).getTime()) / 1000);
		}
		await super.onSubmit();
	}
	
}
