





































	import {Prop, Watch} from 'vue-property-decorator';
	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {CubageLivraison, Chantier} from '@/models';

	import CrudCubageLivraisonEditCreate from '@/components/Crud/CubageLivraison/EditCreate.vue';


	@Component({
		components: {
			CrudCubageLivraisonEditCreate,
		}
	})
	export default class CrudCubageLivraisonEditDialogCreate extends Vue {
		@InOut({ type: CubageLivraison, isVModel: true }) private value!: CubageLivraison;


		@Emit()
		private onSubmit(cubageLivraison: CubageLivraison) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}

		/*protected async refresh(): Promise<void> {
			this.value = ObjectHelper.clone(this.value);

			//console.log("rEFRESH CUBAGE");
		}*/


}
